import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { AutenticadorService } from './services/autenticador/autenticador.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AutenticadorService]
})
export class AppComponent {
  preLoginsLS: any;
  currentApplicationVersion = environment.appVersion;

  constructor() { 
    console.log('AppComponent contructor');
  }

  ngOnInit() {
    
  }

}
