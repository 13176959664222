import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreLoginPeticion } from 'src/app/models/pre-login/PreLogin-peticion';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';

@Component({
  selector: 'app-prelogin',
  templateUrl: './prelogin.component.html',
  styleUrls: ['./prelogin.component.css'],
  providers: [AutenticadorService]
})
export class PreloginComponent implements OnInit {
  preLoginPeticion = new PreLoginPeticion();

  constructor(
    private autenticatorService: AutenticadorService, 
    private router: Router, 
    @Inject('env') private environment: any) { 
  }

  ngOnInit() {
    sessionStorage.clear();
    this.autenticatorService.callPreLogin(true);
  }

}