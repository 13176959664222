import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ModuloAdquirienteWebModule } from 'modulo-adquiriente-web';
import { Ng2Rut } from 'ng2-rut';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CountdownModule } from 'ngx-countdown';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CambioPassComponent } from './components/autenticador/cambio-pass/cambio-pass.component';
import { LoginComponent } from './components/autenticador/login/login.component';
import { MantencionComponent } from './components/autenticador/mantencion/mantencion.component';
import { RecuperarPassCambioClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-cambio-clave/recuperar-pass-cambio-clave.component';
import { RecuperarPassClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-clave/recuperar-pass-clave.component';
import { RecuperarPassCorreoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-correo/recuperar-pass-correo.component';
import { RecuperarPassExitoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-exito/recuperar-pass-exito.component';
import { Interceptor } from './components/autenticador/session-validation/session-interceptor';
import { DialogBaseComponent } from './components/dialogs/dialog-base/dialog-base.component';
import { LoadingBaseComponent } from './components/dialogs/loading-base/loading-base.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { AutenticadorService } from './services/autenticador/autenticador.service';
import { MantencionService } from './services/mantencion.service';
import { ValidaRecuperarPassCambioClaveService } from './services/recuperarPass/validaRecuperarPassCambioClave.service';
import { ValidaRecuperarPassClaveService } from './services/recuperarPass/validaRecuperarPassClave.service';
import { ValidaRecuperarPassCorreoService } from './services/recuperarPass/validaRecuperarPassCorreo.service';
import { ValidaRecuperarPassExitoService } from './services/recuperarPass/validaRecuperarPassExito.service';
import { SecurityService } from './services/security.service';
import { ValidaCambioPassService } from './services/validaCambioPass.service';
import { ValidaloginService } from './services/validaLogin.service';
import { ValidaMenuService } from './services/validaMenu.service';
import { ValidaModuloService } from './services/validaModulo.service';
import { PreloginComponent } from './components/autenticador/prelogin/prelogin.component';
import { SoloRutDirective } from './components/utiles/solo-rut.directive'; 
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    CambioPassComponent,
    MantencionComponent,
    RecuperarPassCorreoComponent,
    RecuperarPassClaveComponent,
    RecuperarPassCambioClaveComponent,
    RecuperarPassExitoComponent,
    DialogBaseComponent,
    LoadingBaseComponent,
    PreloginComponent,
    SoloRutDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    DigitOnlyModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    ModuloAdquirienteWebModule.forRoot(environment),
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    ModalModule.forRoot(),
    CountdownModule,
    Ng2Rut,
    PdfViewerModule

  ],
  exports: [
    LoginComponent,
    HomeComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatTreeModule,
    Ng2Rut
  ],

  providers: [
    AutenticadorService,
    SecurityService,
    MantencionService,
    ValidaMenuService,
    ValidaModuloService,
    ValidaCambioPassService,
    ValidaRecuperarPassCorreoService,
    ValidaRecuperarPassClaveService,
    ValidaRecuperarPassCambioClaveService,
    ValidaRecuperarPassExitoService,
    ValidaloginService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogBaseComponent, LoadingBaseComponent]
})
export class AppModule { }
