import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

   constructor(private router: Router) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (sessionStorage.getItem('tokenSession') != null) {
         console.log('Entro al filter...', request.headers);
         const token = 'Bearer ' + sessionStorage.getItem('tokenSession');
         const headers = new HttpHeaders().set("Authorization", token);
         const authRequest = request.clone({ headers: headers });
         return next.handle(authRequest).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                sessionStorage.removeItem('infoCambioPass');
                sessionStorage.removeItem('currentUser');
                sessionStorage.clear();
                sessionStorage.removeItem('login');
                this.router.navigate(['']);
            }

            const error = 'Error http ' + err.status;
            return throwError(error);
        }));
      } else {
         return next.handle(request);
      }
   }
}