import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidators {

    /**
    * @description
    * Funcion para validar textos como nombres y apellidos
    * Acepta solamente:
    * - letras mayusculas y minusculas
    * - letras con tildes, incluida la enie
    * - espacios en blanco
    */
    public static nameValidator(control: AbstractControl): { [key: string]: any } | null {
        let regex: RegExp = new RegExp('[^\\u0041-\\u005A\\u0061-\\u007A\\u00C1\\u00C9\\u00CD\\u00D3\\u00DA\\u00F1\\u00D1\\u0020\\u00E1\\u00E9\\u00ED\\u00F3\\u00FA]');
        let texto: string = control.value;

        if(texto == null || texto == 'null' || texto == undefined) {
            return null;
        }

        if (texto.length === 0) {
            return null;
        }

        let validacion: boolean = true;

        if (texto.trim().length === 0) {
            validacion = false;
        } else if (regex.test(control.value)) {
            validacion = false;
        }
        //console.log('nameValidator texto', texto, 'validacion', validacion);
        return validacion ? null : { 'nameError': { value: control.value } };
    }

    /**
     * @description
     * Funcion para validar textos como descripciones
     * Acepta solamente:
     * - letras mayusculas y minusculas
     * - numeros., letras con tildes, incluida la enie
     * - espacios en blanco
     * - puntos, comas, signo de suma y resta
     * - guion bajo, ampersand y dos puntos
     * - Parentesis normales
     */
    public static descriptionValidator(control: AbstractControl): { [key: string]: any } | null {
        let regex: RegExp = new RegExp('[^\\u0030-\\u0039\\u0041-\\u005A\\u0061-\\u007A\\u00C1\\u00C9\\u00CD\\u00D3\\u00DA\\u00F1\\u00D1\\u002D\\u0020\\u002B\\u005F\\u00E1\\u00E9\\u00ED\\u00F3\\u00FA\\u002C\\u002E\\u0026\\u003A\\u0028\\u0029]');
        let texto: string = control.value;

        if(texto == null || texto == 'null' || texto == undefined) {
            return null;
        }

        if (texto.length === 0) {
            return null;
        }

        let validacion: boolean = true;

        if (texto.trim().length === 0) {
            validacion = false;
        } else if (regex.test(control.value)) {
            validacion = false;
        }
        //console.log('descriptionValidator texto', texto, 'validacion', validacion);
        return validacion ? null : { 'descripcionError': { value: control.value } };
    }

    /**
     * @description
     * Funcion para validar textos como identificador de servicio
     * Acepta solamente:
     * - letras mayusculas y minusculas
     * - numeros
     * - signo de resta y guion bajo
     */
    public static idServiceValidator(control: AbstractControl): { [key: string]: any } | null {
        let regex: RegExp = new RegExp('[^\\u0030-\\u0039\\u0041-\\u005A\\u0061-\\u007A\\u002D\\u005F]');
        let texto: string = control.value;

        if(texto == null || texto == 'null' || texto == undefined) {
            return null;
        }

        if (texto.length === 0) {
            return null;
        }

        let validacion: boolean = true;

        if (texto.trim().length === 0) {
            validacion = false;
        } else if (regex.test(control.value)) {
            validacion = false;
        }
        //console.log('idServiceValidator texto', texto, 'validacion', validacion);
        return validacion ? null : { 'idServiceError': { value: control.value } };
    }

    /**
     * @description
     * Funcion para validar marca de tarjeta de credito
    */
    public static cardNumberValidator(control: AbstractControl): { [key: string]: any } | null {
        let validacion: boolean = false;

        let cardNumber: string = control.value;

        if(cardNumber == null || cardNumber == 'null' || cardNumber == undefined) {
            return null;
        }

        if (cardNumber.length === 0) {
            return null;
        }

        let bin6digits = cardNumber.substring(0, 6);
        let bin2digits = cardNumber.substring(0, 2);
        let bin1digits = cardNumber.substring(0, 1);

        if (cardNumber.length == 15 && (Number(bin2digits) == 34 || Number(bin2digits) == 37)) {
            //AMEX
            validacion = true;
        } else if (cardNumber.length == 16 && ((Number(bin6digits) >= 510000 && Number(bin6digits) <= 559999) || (Number(bin6digits) >= 222100 && Number(bin6digits) <= 272099))) {
            //MASTER
            validacion = true;
        } else if (cardNumber.length <= 19 && ((Number(bin2digits) == 50) || (Number(bin2digits) >= 56 && Number(bin2digits) <= 64) || (Number(bin2digits) >= 66 && Number(bin2digits) <= 69))) {
            //MAESTRO
            validacion = true;
        } else if (cardNumber.length <= 19 && (Number(bin1digits) == 4)) {
            //VISA
            validacion = true;
        }

        return validacion ? null : { 'cardNumberError': { value: control.value } };
    }


    /**
     * @description
     * Funcion para validar RUT Chileno
     */
    public static rutValidator(control: AbstractControl): { [key: string]: boolean } | null {
        let rut: string = control.value;
        if (rut == '' || rut == 'null' || rut == undefined) return null;
        rut = rut.trim();
        rut = rut.replace('.', '').replace('-', '');

        let factor = 2, suma = 0, l = rut.length - 1, digitoOriginal = rut.slice(-1).toLowerCase(), digitoCalculado;

        while (l--) {
            suma += +rut[l] * factor;
            factor = factor === 7 ? 2 : ++factor;
        }

        digitoCalculado = 11 - (suma % 11);
        digitoCalculado = digitoCalculado === 11 ? 0 : (digitoCalculado === 10 ? 'k' : digitoCalculado);

        if (digitoCalculado != digitoOriginal) {
            return { 'rutError': true };
        } else {
            return null;
        }
    }

    /**
     * @description
     * Funcion para validar solo numeros
     */
    public static numberValidator(control: AbstractControl): { [key: string]: any } | null {
        let regex: RegExp = new RegExp('[^\\u0030-\\u0039]');
        let texto: string = '';
        texto = texto + control.value;
        
        if(texto == null || texto == 'null' || texto == undefined) {
            return null;
        }

        if (texto.length === 0) {
            return null;
        }

        let validacion: boolean = true;

        if (texto.trim().length === 0) {
            validacion = false;
        } else if (regex.test(control.value)) {
            validacion = false;
        }
        //console.log('numberValidator texto', texto, 'validacion', validacion);
        return validacion ? null : { 'numberError': true };
    }

     /**
     * @description
     * Funcion para validar solo numeros positivos (menos 0)
     */
    public static numberValidatorNonZero(control: AbstractControl): { [key: string]: any } | null {
        let regex: RegExp = new RegExp('[^\\u0030-\\u0039]');
        let texto: string = '';
        texto = texto + control.value;
        
        if(texto == null || texto == 'null' || texto == undefined) {
            return null;
        }

        if (texto.length === 0) {
            return null;
        }

        let validacion: boolean = true;

        if (texto.trim().length === 0) {
            validacion = false;
        } else if (regex.test(control.value)) {
            validacion = false;
        }if(texto == '0'){
            validacion = false;
        }
        //console.log('numberValidator texto', texto, 'validacion', validacion);
        return validacion ? null : { 'numberError': { value: control.value } };
    }

    /**
     * @description
     * Funcion para validar monto con punto de miles
     */
    public static amountValidator(control: AbstractControl): { [key: string]: any } | null {
        let regex: RegExp = new RegExp('[^\\u0030-\\u0039\\u002E]');
        let texto: string = control.value;

        if(texto == null || texto == 'null' || texto == undefined) {
            return null;
        }

        if (texto.length === 0) {
            return null;
        }

        let validacion: boolean = true;

        if (texto.trim().length === 0) {
            validacion = false;
        } else if (regex.test(control.value)) {
            validacion = false;
        }
        //console.log('amountValidator texto', texto, 'validacion', validacion);
        return validacion ? null : { 'amountError': { value: control.value } };
    }

    public static validaMes(control: AbstractControl): { [key: string]: boolean } | null {
        let mes: string = control.get('mesMedioPago').value;
        if (mes == '' || mes == 'null' || mes == undefined) return null;
        let mesNumero: number = Number.parseInt(mes);
        let hoy = new Date();
        let mesHoy: number = hoy.getMonth() + 1;

        let anhoHoy = (hoy.getFullYear()).toString();
        let anhoHoyValue = Number.parseInt((anhoHoy + "").substr(2, 4));

        let anho: string = control.get('anioMedioPago').value;
        let anhoValue = Number.parseInt(anho);

        if (anho != '') {
            if (anhoValue == anhoHoyValue) {
                if (mesNumero < mesHoy) {
                    control.get('mesMedioPago').setErrors({ 'errorMes': true });
                    return { 'errorMes': true };
                } else {
                    return null;
                }
            } else {
                control.get('mesMedioPago').setErrors(null);
                return null;
            }
        } else {
            return null;
        }
    }

}
