import { Component, HostListener, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { Renovartoken } from 'src/app/models/login/renovartoken';
import { AutenticadorService } from 'src/app/services/autenticador/autenticador.service';
import { DialogBaseComponent } from '../dialogs/dialog-base/dialog-base.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  userPerfil: { [x: string]: any; };
  perfil: any;
  usuario: any;

  @ViewChild('countdown', null) counter: CountdownComponent;
  countdownConfig: CountdownConfig = {};

  @ViewChild('countdownInactivity', null) counterIncativity: CountdownComponent;
  countdownInactivityConfig: CountdownConfig = {};

  constructor(
    private router: Router,
    private service: AutenticadorService,
    private dialog: MatDialog,
    @Inject('env') private environment: any
  ) { }

  ngOnInit() {
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    this.perfil = this.userPerfil['perfil'];
    this.usuario = this.userPerfil['usuario'];
    if (sessionStorage.getItem('urlSelected') == null || (this.perfil.modulos[0].url == sessionStorage.getItem('urlSelected'))) {
      sessionStorage.setItem('urlSelected', this.perfil.modulos[0].url);
    }

    //Se configura el contador de expiracion de token
    let minExpSession: number = this.perfil.polMinExpSesion * 60;
    this.countdownConfig.leftTime = minExpSession;
    this.countdownConfig.notify = [30];
    this.counter.config = this.countdownConfig;

    //Se configura el contador de expiracion de inactividad
    let minExpInactivity: number = this.perfil.polMinExpSesion * 60;
    this.countdownInactivityConfig.leftTime = minExpInactivity;
    this.countdownInactivityConfig.notify = [30];
    this.counterIncativity.config = this.countdownInactivityConfig;
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetInactivity() {
    this.counterIncativity.restart();
  }

  logout() {
    //Se destruye en token en BD
    this.cerrarSesionToken();

    //Destruye variables de sesion y hace logout
    sessionStorage.clear();
    this.router.navigate(['']);
  }

  public cambioPass() {
    this.router.navigate(['cambiopass']);
  }

  urlSelected(event) {
    sessionStorage.setItem('urlSelected', event);
  }

  handleEvent(event: CountdownEvent) {
    if (event.action == 'notify') {
      this.renovarToken();
    }
  }

  handleEventInactivity(event: CountdownEvent) {
    if (event.action == 'notify') {
      console.log("Usario sin actividad...");
      this.showDialog();
    }
    if (event.action == 'done') {
      console.log("No hubo actividad del usuario");
      this.dialog.closeAll();
      this.logout();
    }
  }

  renovarToken() {
    let preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    let params: Renovartoken = new Renovartoken();
    params.codCliente = this.environment.codCliente;
    params.codTipoPortal = preLoginsLS.codTpoPortal;
    params.docEmpresa = this.userPerfil.empresa.docEmpresa;
    params.docUsuario = sessionStorage.getItem('docUsuario');
    params.token = sessionStorage.getItem('tokenSession');
    this.service.renovarToken(params).subscribe(result => {
      let codRetorno = result.codret;
      if (codRetorno == '0') {
        sessionStorage.setItem('tokenSession', result.token);
        this.counter.restart();
      } else {
        console.log("Error al renovar el token en servicio: " + result.descret);
        this.logout();
      }
    }, err => {
      console.log("Error al llamar a servicio para renovar token");
      this.logout();
    })

  }

  cerrarSesionToken() {
    let preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));
    let params: Renovartoken = new Renovartoken();
    params.codCliente = this.environment.codCliente;
    params.codTipoPortal = preLoginsLS.codTpoPortal;
    params.docEmpresa = this.userPerfil.empresa.docEmpresa;
    params.docUsuario = sessionStorage.getItem('docUsuario');
    params.token = sessionStorage.getItem('tokenSession');
    this.service.renovarToken(params).subscribe(result => {
      let codRetorno = result.codret;
      if (codRetorno == '0') {
        console.log("Borrado de token exitoso");
      } else {
        console.log("Error al renovar el token en servicio: " + result.descret);
      }
    }, err => {
      console.log("Error al llamar a servicio para renovar token");
    })

  }

  dialogRef: MatDialogRef<DialogBaseComponent, null>;

  showDialog() {
    this.dialogRef = this.dialog.open(DialogBaseComponent, {
      data: { title: 'Advertencia', content: 'Por inactividad se cerrará la sesión.', showBtnCancel: false },
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


}
