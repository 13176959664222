import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModuloAdquirienteWebModule } from 'modulo-adquiriente-web';
import { AppComponent } from './app.component';
import { CambioPassComponent } from './components/autenticador/cambio-pass/cambio-pass.component';
import { LoginComponent } from './components/autenticador/login/login.component';
import { MantencionComponent } from './components/autenticador/mantencion/mantencion.component';
import { RecuperarPassCambioClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-cambio-clave/recuperar-pass-cambio-clave.component';
import { RecuperarPassClaveComponent } from './components/autenticador/recuperar-pass/recuperar-pass-clave/recuperar-pass-clave.component';
import { RecuperarPassCorreoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-correo/recuperar-pass-correo.component';
import { RecuperarPassExitoComponent } from './components/autenticador/recuperar-pass/recuperar-pass-exito/recuperar-pass-exito.component';
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { MantencionService } from './services/mantencion.service';
import { ValidaRecuperarPassCambioClaveService } from './services/recuperarPass/validaRecuperarPassCambioClave.service';
import { ValidaRecuperarPassClaveService } from './services/recuperarPass/validaRecuperarPassClave.service';
import { ValidaRecuperarPassCorreoService } from './services/recuperarPass/validaRecuperarPassCorreo.service';
import { ValidaRecuperarPassExitoService } from './services/recuperarPass/validaRecuperarPassExito.service';
import { SecurityService } from './services/security.service';
import { ValidaCambioPassService } from './services/validaCambioPass.service';
import { ValidaloginService } from './services/validaLogin.service';
import { ValidaMenuService } from './services/validaMenu.service';
import { ValidaModuloService } from './services/validaModulo.service';
import { PreloginComponent } from './components/autenticador/prelogin/prelogin.component';


const routes: Routes = [
  { path: 'prelogin', component: PreloginComponent },
  
  { path: 'login', component: LoginComponent, canActivate: [ValidaloginService] },
  { path: 'cambiopass', component: CambioPassComponent, canActivate: [ValidaCambioPassService] },
  { path: 'recuperarpasscorreo', component: RecuperarPassCorreoComponent, canActivate: [ValidaRecuperarPassCorreoService] },
  { path: 'recuperarpassclave', component: RecuperarPassClaveComponent, canActivate: [ValidaRecuperarPassClaveService] },
  { path: 'recuperarpasscambioclave', component: RecuperarPassCambioClaveComponent, canActivate: [ValidaRecuperarPassCambioClaveService] },
  { path: 'recuperarpassexito', component: RecuperarPassExitoComponent, canActivate: [ValidaRecuperarPassExitoService] },

  { path: 'mantencion', component: MantencionComponent, canActivate: [MantencionService] },
  { path: '', redirectTo: 'prelogin', pathMatch: 'full' },
  {
    path: 'home', component: HomeComponent, canActivate: [SecurityService],
    children: [
      { path: '', redirectTo: 'operador', pathMatch: 'full' },
      { path: 'operador', component: MenuComponent, loadChildren: () => ModuloAdquirienteWebModule, canActivate: [ValidaModuloService], canActivateChild: [ValidaMenuService] },
      { path: 'gestion', component: MenuComponent, loadChildren: () => ModuloAdquirienteWebModule, canActivate: [ValidaModuloService], canActivateChild: [ValidaMenuService] },
      { path: 'seguridad', component: MenuComponent, loadChildren: () => ModuloAdquirienteWebModule, canActivate: [ValidaModuloService], canActivateChild: [ValidaMenuService] },
      { path: '**', redirectTo: 'prelogin' }
    ]
  },
  { path: '**', redirectTo: 'prelogin' }//de otra manera redirecciona a login
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
