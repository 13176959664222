import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-loading-base',
  templateUrl: './loading-base.component.html',
  styleUrls: ['./loading-base.component.css']
})
export class LoadingBaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
