import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class MantencionService implements CanActivate {
  preLogin: { estadoPortal: string; codret: string; };
  userPerfil: { [x: string]: { [x: string]: number; }; };
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('preLogin')) {
      this.preLogin = JSON.parse(sessionStorage.getItem('preLogin'));
    }
    if (sessionStorage.getItem('userPerfil')) {
      this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    }

    if (sessionStorage.getItem('preLoginError') || this.preLogin.estadoPortal == "SUSPENDIDO") {
      return true;
    }
    if (sessionStorage.getItem('userPerfil')) {
      if (this.userPerfil['perfil']['idStdPrf'] != 1) {
        return true;
      }
    }
    // Si no esta activa la sesion, redirecciona al inicio
    this.router.navigate(['']);
    return false;
  }
}