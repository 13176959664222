import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: []
})
export class MenuComponent {
  userPerfil;
  perfil;
  urlSelected: string;
  showHome: boolean = true;
  constructor(public router: Router) { }

  ngOnInit() {
    this.urlSelected = sessionStorage.getItem('urlSelected');
    this.userPerfil = JSON.parse(sessionStorage.getItem('userPerfil'));
    this.perfil = this.userPerfil['perfil'];
    this.showHome = true;
  }
  ngDoCheck() {
    this.onClickMenu(this.router.url, this.perfil.modulos[0].url);
  }
  onClickMenu(url: any, primerMenu: any) {
    (url == '/home/' + primerMenu) ? this.showHome = true : this.showHome = false;
  }

}