import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CambioPassPeticion } from 'src/app/models/cambio-pass/cambioPass-peticion';
import { LoginPeticion } from 'src/app/models/login/login-peticion';
import { PoliticaPeticion } from 'src/app/models/politica/politica-peticion';
import { PreLoginPeticion } from 'src/app/models/pre-login/PreLogin-peticion';
import { Renovartoken } from 'src/app/models/login/renovartoken';
import { Renovartokenresponse } from 'src/app/models/login/renovartokenresponse';
import { PreLoginResponse } from 'src/app/models/pre-login/PreLogin-response';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class AutenticadorService {
  constructor(private http: HttpClient, private router: Router, private titleService: Title) { }

  urlPreLogin = environment.urlAmbiente + '/apiUsuarios/logincliente';
  urlLogin = environment.urlAmbiente + '/apiUsuarios/login';
  urlCambioPass = environment.urlAmbiente + '/apiUsuarios/cambioclave';
  urlPolitica = environment.urlAmbiente + '/apiUsuarios/traepolitica';
  urlRenovarToken = environment.urlAmbiente + '/apiUsuarios/renovartoken';
  urlCerrarSesion = environment.urlAmbiente + '/apiUsuarios/cerrarsesion';

  preLogin(_body: PreLoginPeticion): Observable<PreLoginResponse> {
    return this.http.post<PreLoginResponse>(this.urlPreLogin, _body, httpOptions);
  }

  doLogin(_body: LoginPeticion): Observable<any> {
    return this.http.post<any>(this.urlLogin, _body, httpOptions);
  }

  doCambioPass(_body: CambioPassPeticion): Observable<any> {
    return this.http.post<any>(this.urlCambioPass, _body, httpOptions);
  }

  doPolitica(_body: PoliticaPeticion): Observable<any> {
    return this.http.post<any>(this.urlPolitica, _body, httpOptions);
  }

  renovarToken(params: Renovartoken): Observable<Renovartokenresponse> {
    return this.http.post<Renovartokenresponse>(this.urlRenovarToken, params, httpOptions);
  }

  cerrarSesion(params: Renovartoken): Observable<Renovartokenresponse> {
    return this.http.post<Renovartokenresponse>(this.urlCerrarSesion, params, httpOptions);
  }

  callPreLogin(redirecciona: boolean) {
    let params: PreLoginPeticion = new PreLoginPeticion();
    params.codCliente = environment.codCliente;
    params.urlPortal = environment.urlPortal;

    this.preLogin(params).subscribe(data => {
      if (data.codret == '0') {
        console.log("PreLogin", data);
        sessionStorage.setItem('preLogin', JSON.stringify(data));
        this.setPageTitle();
        console.log("data.estadoPortal", data.estadoPortal);
        if (data.estadoPortal == 'SUSPENDIDO') {
          this.router.navigate(['/mantencion']);
        } else {
          sessionStorage.setItem('tokenSession', data.tokenSession);
          if(redirecciona){
            this.router.navigate(['/login']);
          }
        }
      } else {
        console.log('Error preLogin: ', data.descret);
        sessionStorage.setItem('preLoginError', JSON.stringify(data));
        this.router.navigate(['/mantencion']);
      }
    }, error => {
      sessionStorage.setItem('preLoginError', JSON.stringify(error));
      this.router.navigate(['/mantencion']);
    });
  }

  setPageTitle(): void{
    let preLoginsLS = JSON.parse(sessionStorage.getItem('preLogin'));

    if (preLoginsLS.codTpoPortal == 'COMERCIO') {
      this.titleService.setTitle('Portal Comercio');
    }else {
      this.titleService.setTitle('Portal Adquirente');
    }

  }

}