export class RecuperarPassCambioClavePeticion{
    codCliente:string;
    codTpoPortal:string;
    docEmpresa:string;
       
    docUsuario: string;
    claveActual: string;
    
    claveNueva: string;
    claveRepetida: string;
    
    tipoCambio: string;
}