import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mantencion',
  templateUrl: './mantencion.component.html',
  styleUrls: ['./mantencion.component.css']
})
export class MantencionComponent implements OnInit {
  loading = false;
  errorMensage: string;
  preLogin;
  constructor(private router: Router) { }

  ngOnInit() {
    if(sessionStorage.getItem('preLogin')){
      this.preLogin = JSON.parse(sessionStorage.getItem('preLogin'));
    }
    if(sessionStorage.getItem('preLoginError')){
      this.errorMensage = 'Servicio temporalmente no disponible.';
    } else {
      if(sessionStorage.getItem('preLogin') && this.preLogin.estadoPortal == 'SUSPENDIDO'){
          this.errorMensage = 'PORTAL ' + this.preLogin.estadoPortal + '.';
      }else{
        if(sessionStorage.getItem('userPerfil')){
          this.errorMensage = 'Perfil suspendido.';
        }
      }
    }
  }

  reintentar() {
    this.loading = true;
    this.router.navigate(['/prelogin']);
  }
}
