import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RecuperarPassCambioClavePeticion } from 'src/app/models/recuperar-pass/recuperarPassCambioClave-peticion';
import { RecuperarPassClavePeticion } from 'src/app/models/recuperar-pass/recuperarPassClave-peticion';
import { RecuperarPassCorreoPeticion } from 'src/app/models/recuperar-pass/recuperarPassCorreo-peticion';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class RecuperarPassService {
  /*  urlCrearCuenta = environment.urlAmbiente + '/apiUsuarios/crearusuario'; */
  constructor(private http: HttpClient, @Inject('env') private environment: any) { }
  
  urlRecuperarPassCorreo = this.environment.urlAmbiente + '/apiUsuarios/recuperarclave';
  urlRecuperarPassClave = this.environment.urlAmbiente + '/apiUsuarios/validausuariocorreo';
  urlRecuperarPassCambioClave = this.environment.urlAmbiente + '/apiUsuarios/cambioclave';

  doRecuperarCorreo(_body: RecuperarPassCorreoPeticion): Observable<any> {
    return this.http.post<any>(this.urlRecuperarPassCorreo, _body, httpOptions);
  }
  doRecuperarClave(_body: RecuperarPassClavePeticion): Observable<any> {
    return this.http.post<any>(this.urlRecuperarPassClave, _body, httpOptions);
  }
  doRecuperarPassCambioClave(_body: RecuperarPassCambioClavePeticion): Observable<any> {
    return this.http.post<any>(this.urlRecuperarPassCambioClave, _body, httpOptions);
  }
}