import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ValidaloginService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('preLogin')) {
      console.log("sesion activa");
      return true;
    } else {
      console.log("sesion NO activa");
      this.router.navigate(['']);
      return false;
    }

  }

}