export class CambioPassPeticion {
    codCliente: string = "-1";
    docEmpresa: string = "-1";
    docUsuario: string = "-1";
    claveActual: string = "-1";
    claveNueva: string = "-1";
    claveRepetida: string = "-1";
    codTpoPortal: string = "-1";

    tipoCambio: string;
}