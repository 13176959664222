import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class ValidaRecuperarPassCorreoService implements CanActivate {
  
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('login')) {
      return true;
    }else{
      // Si no esta activa la sesion, redirecciona al inicio
      this.router.navigate(['']); 
      return false;
    }
    
  }

}